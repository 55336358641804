import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HeroSection from '../../HeroSection';
import './style.css';
import {
  categoriesOptionDetails as categoryOptionMap,
  descriptionTiles as descriptionArray,
  languageMapper,
} from './util';
import InfoSection from '../../Common/InfoSection';
import Tabs from '../../Common/Tabs';
import WorkshopImageInfoCard from '../../Common/WorkshopImageInfoCard';
import DescriptionSlide from '../../Common/DescriptionSlide';
import Button from '../../Common/Button';
import Modal from '../../Common/Modal';
import Signup from '../../Forms/Signup/index';
import apiMethod from '../../../services/apiService';
import Util from '../../../Util/Util';
import { useOutletContext } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import defaultProfileImage from '../../../Assets/Creator_Place_Holder.svg';
import defaultCoverImage from '../../../Assets/default_workshop_cover_pic.jpg';

const Workshop = () => {
  const [setLoader] = useOutletContext();
  const [workShopData, setWorkshopData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState(''); // State for email
  const [phone, setPhone] = useState(''); // State for phone
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    loadHandler();
  }, []);

  // useEffect(() => {
  //   GA4React.pageview(location.pathname);
  // }, [location.pathname]);

  let { id } = useParams();

  const loadHandler = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const affiliate = queryParams.get('affiliate'); // Get referral from query parameter

    const url = `/v1/workshop/${id}`;
    try {
      setLoader(true);
      const data = await apiMethod.get(url);
      const baseUrl = process.env.REACT_APP_API_ENDPOINT;
      let image;
      let coverImage;
      if (data?.body?.webPicture != null) {
        console.log('optimized cover image');
        coverImage = data?.body?.webPicture;
      } else {
        let coverPicUrl = `${baseUrl}/v1/file/workshop/${data?.body?.coverPicture}`;
        const coverImageData = await Util.fetchBlob(coverPicUrl);
        coverImage = coverImageData ? URL.createObjectURL(coverImageData) : defaultCoverImage;
      }
      if (data?.body?.creator?.webPicture != null) {
        console.log('optimized profile image');
        image = data?.body?.creator?.webPicture;
      } else {
        let imageUrl = `${baseUrl}/v1/file/profile/${data?.body?.creator?.profilePic}`;
        const imageData = await Util.fetchBlob(imageUrl);
        image = imageData ? URL.createObjectURL(imageData) : defaultProfileImage;
      }
      const updatedData = await dataTransformer(data, image, coverImage);
      setWorkshopData(updatedData);
      setLoader(false);
      const key = 'workshopId';
      localStorage.setItem(key, id.toString());
      // Store referral in localStorage if it exists
      if (affiliate) {
        localStorage.setItem('affiliate', affiliate);
      }
      console.log('reaching analytics code');
      apiMethod
        .post(true, `${baseUrl}/v1/analytics/workshop`, {
          event: 'workshop hit',
          workshopId: id,
        })
        .catch(() => {});
      console.log('reaching analytics code');
    } catch (error) {
      console.log('error', error);
      setLoader(false);
      navigate('/');
    }
  };

  const dataTransformer = async (data, image, coverImage) => {
    const categoriesOption = data?.body?.categories?.map((item) => {
      return item?.title;
    });
    const categoriesOptionDetails = categoryOptionMap.map((item) => {
      return {
        key: item?.key,
        imageURL: item?.imageURL,
        label: getInfoSectionLabels(item?.key, data),
      };
    });
    const creatorData = data?.body?.creator;
    console.log(data.body.details);
    //data.body.details = data?.body?.details.replace(/\\n/g, '<br>');
    const descriptionTiles = descriptionArray.map((item) => {
      let headingOverride = item?.heading;
      console.log(item);
      if (
        item.key === 'webAudienceDescription' &&
        data?.body?.webAudienceDescriptionOverride != null &&
        data?.body?.webAudienceDescriptionOverride != ''
      ) {
        console.log('webAudienceDescription');
        console.log(data?.body?.webAudienceDescriptionOverride);
        headingOverride = data?.body?.webAudienceDescriptionOverride;
      }
      if (
        item.key === 'webAudienceDescription' &&
        data?.body?.webDetailsOverride != null &&
        data?.body?.webDetailsOverride != ''
      ) {
        console.log('webDetails');
        console.log(data?.body?.webDetailsOverride);
        headingOverride = data?.body?.webDetailsOverride;
      }
      return { description: data?.body[item?.key] || item?.description, heading: headingOverride };
    });
    return {
      categoriesOption,
      title: data?.body?.title,
      categoriesOptionDetails,
      description: data?.body?.webDescription,
      time: data?.body?.registrationClosingDate,
      creator: creatorData,
      price: data?.body?.pricePerSeat,
      webAudienceDescription: data?.body?.webAudienceDescription,
      descriptionTiles,
      coverPhoto: coverImage,
      workshopEssentials: data?.body?.details,
      date: data?.body?.date,
      profilePic: image,
      currency: data?.body?.currency,
      webDescriptionOverride: data?.body?.webDescriptionOverride,
      webAudienceDescriptionOverride: data?.body?.webAudienceDescriptionOverride,
      webDetailsOverride: data?.body?.webDetailsOverride,
    };
  };

  const clickHandler = () => {
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;
    apiMethod
      .post(true, `${baseUrl}/v1/analytics/workshop`, {
        event: 'workshop buy now clicked',
        workshopId: id,
      })
      .catch(() => {});
    console.log('opening modal as true');
    setOpenModal(true);
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const getInfoSectionLabels = (key, data) => {
    switch (key) {
      case 'language': {
        return languageMapper[data?.body[key]];
      }
      case 'time': {
        let time = 'IST';
        if (data?.body?.currency === 'AED') {
          time = 'GST';
        }
        return `${Util?.formatDate(data?.body?.date)} - ${Util?.formatTime(data?.body?.time)} ${time}`;
      }
      default: {
        return data?.body[key];
      }
    }
  };

  return (
    <>
      {workShopData?.title && (
        <Helmet>
          <title> {workShopData?.title} </title>
          <meta name="og:image" content={workShopData?.coverPhoto} />
          <meta name="description" content={workShopData?.description} />
        </Helmet>
      )}
      <HeroSection
        heading="Buzz Event"
        backgroundImg={workShopData?.coverPhoto}
        //description={`Enrollment ends ${Util?.formatDate(workShopData?.time)} Claim your spot now. ⌛ !!`}
        completeDateTime={workShopData?.time}
      />
      <div className="newbies_section mt-[40px] mb-[20px]">
        <div className="main_container">
          <div className="heading_section flex mt-24 align-middle">
            <div className="flex items-left align-middle w-3/4 py-[0%] px-[5%]">
              <p className="text-[24px] align-middle lg:text-[30px] font-bold text-buzzpurple leading-tight">
                {workShopData?.title}
                <WorkshopImageInfoCard
                  creator={workShopData?.creator}
                  profilePic={workShopData?.profilePic}
                />
              </p>
            </div>
            <div className="flex flex-col py-[5%]">
              <p className="text-[20px] text-center text-buzzpink font-semibold ml:auto">{`${workShopData.currency} ${workShopData?.price?.toFixed(2)}`}</p>
            </div>
          </div>
          <div className="Categories_wrapper pt-[10px] pb-[10px]">
            {/* <h3 className="text-[24px] text-buzzpurple font-medium text-center my-5">Categories</h3> */}
            {/* <Tabs option={workShopData?.categoriesOption} /> */}
            <InfoSection data={workShopData?.categoriesOptionDetails} />
          </div>
        </div>
      </div>
      <div className="mb-[50px]">
        <div className="main_container">
          {/* <p className="sm:text-[16px] text-[20px] text-buzzpurple font-medium text-center leading-tight">
            {workShopData?.webDescriptionOverride || 'Description'}
          </p> */}
          <p
            className="lg:text-[18px] text-14px font-medium italic my-[15px] text-neutral-600"
            dangerouslySetInnerHTML={{ __html: workShopData?.description }}
          />
          <div className="py-[2%]">
            {workShopData?.descriptionTiles?.map((item, index) => (
              <DescriptionSlide data={item} key={`tiles-${index}`} />
            ))}
          </div>
          <div className="flex justify-center">
            <Button
              className={'multi_color_btn w-3/8'}
              content={`Buy Ticket ${workShopData.currency} ${workShopData?.price?.toFixed(2)} -->`}
              onClick={clickHandler}
            />
          </div>
          <p style={{ fontSize: '0.8rem', color: '#555', marginTop: '10px', textAlign: 'center' }}>
            Secured with{' '}
            <a
              href="https://telr.com/about-telr/"
              style={{ color: '#0000FF', textDecoration: 'none' }}
              target="_blank"
              rel="noopener noreferrer">
              Telr
            </a>
          </p>
        </div>
      </div>
      <Footer />
      {openModal && (
        <Modal
          className="workshop_dialog"
          id=""
          open={open}
          handleClose={() => modalCloseHandler()}
          bodyClass="!border-0"
          showCloseButton={false}>
          {<Signup />}
        </Modal>
      )}
    </>
  );
};

export default Workshop;
