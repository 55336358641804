import axiosInstance from '../config/interceptor';

const get = (url, queryParams = '') => {
  const res = axiosInstance.get(url, {
    params: queryParams,
    headers: {
      // 'Content-Type': 'application/json',
      // Accept: 'application/json',
    },
  });
  return res;
};

const post = (analytics, url, body, queryParams = '') => {
  if (analytics === true) {
    console.log('analytics api encountered');
    var blockAnalytics = localStorage.getItem('affiliate');
    if (blockAnalytics != null) {
      if (blockAnalytics === '55d34e7e950af80fde745612f866720f') {
        console.log('block analytics is found');
        return Promise.resolve();
      }
    }
  }
  return axiosInstance
    .post(url, body, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => res);
};

const put = (url, body, queryParams = '') => {
  return axiosInstance
    .put(url, body, {
      params: queryParams,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    .then((res) => res.data);
};

const apiMethod = { get, post, put };

export default apiMethod;
