import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

const WorkshopImageInfoCard = ({ creator, profilePic, userName = '' }) => {
  let id = creator?.id;
  console.log('id is==>', id);
  // console.log('creator', creator);
  const navigate = useNavigate();
  return (
    <div
      className="ImageInfoCard_details flex w-full my-4 items-center justify-start gap-2"
      onClick={() => {
        navigate(`/creator/${id}`);
      }}>
      <div className="w-full justify-center align-middle image max-h-8 max-w-8 cursor-pointer">
        <img src={profilePic} alt="person" className="rounded-full" />
      </div>
      <div className=" text-[16px] font-normal text-[#0000008A] break-all">
        {/* <span className="text-neutral-800"> Created by </span> */}
        <span className=" text-neutral-800">{creator?.defaultSocialMediaHandle || userName}</span>
      </div>
    </div>
  );
};

export default WorkshopImageInfoCard;
