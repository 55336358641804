import React from 'react';
import './style.css';

const DescriptionSlide = (props) => {
  const { data } = props;

  return (
    <div className="tiles">
      <h3 className="text-buzzpurple font-medium mb-[28px] text-center text-[18px] lg:text-[24px]">
        {data?.heading}
      </h3>
      <p
        className="text-[14px] lg:text-[16px] font-normal leading-tight"
        dangerouslySetInnerHTML={{ __html: data?.description }}></p>
    </div>
  );
};
export default DescriptionSlide;
