import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { DevTool } from '@hookform/devtools';
import './style.css';
import Button from '../../Common/Button';
import apiMethod from '../../../services/apiService';

const Signup = () => {
  const [setLoader] = useOutletContext();
  const [verifyOtpData, setVerifyOtpData] = useState({});
  const [selectedChannel, setSelectedChannel] = useState('');
  const [isPopup, setIsPopup] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [enrolledStart, setenrolledStart] = useState(false);
  // const [emailStatus, setEmailStatus] = useState(true);
  const navigate = useNavigate();
  const form = useForm();
  const { register, control, handleSubmit, formState, getValues, setError, getFieldState } = form;
  console.log('formState', formState.name);
  console.log('getFieldState', getFieldState);
  console.log('verifyOtpData', verifyOtpData);
  const { errors } = formState;

  useEffect(() => {
    console.log('in use effect===>');
    const key = 'workshopId';
    localStorage.getItem(key);
    let workshopIdString = localStorage.getItem(key);
    let workshopId = Number(workshopIdString);
    const userKey = 'payUserId';
    let payUserIdString = localStorage.getItem(userKey);
    console.log('payUserId is ${payUserIdString}');
    let payUserId = Number(payUserIdString);
    console.log('initial payUserId ${payUserId}');
    let interval = setInterval(async () => {
      payUserId = Number(localStorage.getItem('payUserId'));
      console.log('payUserId inside the loop is', payUserId);
      const url = `/v1/payment/isEnrolled`;
      const payload = {
        productType: 'WORKSHOP',
        productId: workshopId,
        userId: payUserId,
      };
      if (enrolledStart) {
        if (payUserId != null) {
          const response = await apiMethod.post(false, url, payload);
          console.log('response enrolled', response);
          if (response?.body) {
            navigate(`/next-step`);
          }
        }
      } else {
        console.log('user id is null picking up again');
      }
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [enrolledStart]);

  // Function to handle iframe load event
  //const handleIframeLoad = () => {
  // Set iframe src to paymentLink again
  //document.getElementById('payment-iframe').contentWindow.location.reload();
  //const iframe = document.getElementById('payment-iframe');
  //if (iframe) {
  // eslint-disable-next-line no-self-assign
  //   iframe.src = iframe.src; // Reload the iframe indirectly
  // }
  // };

  // useEffect(() => {
  //   // Add event listener for iframe load event
  //   const iframe = document.getElementById('payment-iframe');
  //   iframe.addEventListener('load', handleIframeLoad);
  //   return () => {
  //     // Clean up event listener when component unmounts
  //     iframe.removeEventListener('load', handleIframeLoad);
  //   };
  // }, []);

  const submitHandler = async (data) => {
    console.log('in payment handler');
    const key = 'workshopId';
    localStorage.getItem(key);
    let workshopIdString = localStorage.getItem(key);
    let workshopId = Number(workshopIdString);
    let affiliate = localStorage.getItem('affiliate');
    const payload = {
      email: data?.email,
      phoneNumber: data?.mobile,
      firstName: data?.firstname,
      lastName: data?.lastname,
      countryCode: data?.countryCode,
      productId: workshopId,
      productType: 'WORKSHOP',
      applicationLanguage: 'EN',
      affiliateCode: 'test-affiliate',
    };
    setenrolledStart(true);
    try {
      const url = `/v1/user/quick-sign-pay`;
      setLoader(true);
      const response = await apiMethod.post(false, url, payload);
      setLoader(false);
      const baseUrl = process.env.REACT_APP_API_ENDPOINT;
      //let workshopIdString = localStorage.getItem(key);
      //let workshopId = Number(workshopIdString);
      apiMethod
        .post(true, `${baseUrl}/v1/analytics/workshop`, {
          event: 'post sign up payment click',
          workshopId: workshopId,
        })
        .catch(() => {});

      console.log('response', response);
      const key = 'payUserId';
      localStorage.setItem(key, response?.body?.userId.toString());
      console.log(' setting local payment ${response?.body?.userId.toString()}');
      setPaymentLink(response?.body?.paymentUrlLink);
      console.log('payment link is ${response?.body?.paymentUrlLink}');
      // Ensure the window.open call is in response to a user action
      // const userConfirmed = window.confirm(
      //   'To pay with apple pay, allow safari to open chrome in new tab',
      // );
      // if (userConfirmed) {
      //   window.open(response?.body?.paymentUrlLink, '_blank');
      //   setIsPopup(true);
      // }
      //window.open(response?.body?.paymentUrlLink, '_blank');
      setIsPopup(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onCloseClick = () => {
    setIsPopup(false);
  };

  const sendOtpHandler = async (channel) => {
    console.log('data for otp');
    try {
      const url = `/v1/auth/prelogin/${channel}/otp/send`;
      const mobilePayload = {
        mobileNumber: getValues('mobile'),
        mobileCountryCode: getValues('countryCode') || '+971',
      };
      const emailPayload = {
        email: getValues('email'),
      };
      const finalPayload = channel === 'mobile' ? mobilePayload : emailPayload;
      setLoader(true);
      const data = await apiMethod.post(false, url, finalPayload);
      const baseUrl = process.env.REACT_APP_API_ENDPOINT;
      setLoader(false);
      console.log('channel', channel);
      window.alert('a code has been sent to your WhatsApp');
      // channel === 'email ' ? setEmailStatus(true) : setEmailStatus(false);
      console.log('send otp API data is', data);
      let workshopIdString = localStorage.getItem('workshopId');
      let workshopId = Number(workshopIdString);
      apiMethod
        .post(true, `${baseUrl}/v1/analytics/workshop`, {
          event: 'send otp',
          workshopId: workshopId,
        })
        .catch(() => {});
    } catch (error) {
      console.log('error', error);
    }
  };

  const verifyOtpHandler = async (channel) => {
    try {
      const url = `/v1/auth/prelogin/${channel}/otp/verify`;
      const mobilePayload = {
        mobileNumber: getValues('mobile'),
        mobileCountryCode: getValues('countryCode'),
        otp: getValues('phonecode'),
      };
      const emailPayload = {
        email: getValues('email'),
        otp: getValues('emailcode'),
      };
      const finalPayload = channel === 'mobile' ? mobilePayload : emailPayload;
      setLoader(true);
      const verifyOtpData = await apiMethod.post(false, url, finalPayload);
      setLoader(false);
      console.log('verify otp API data is', verifyOtpData);
      const errorField = channel === 'mobile' ? 'phonecode' : 'emailcode';
      verifyOtpData?.message === 'MANDATORY_EMAIL'
        ? setError('email', { type: 'custom', message: verifyOtpData?.description })
        : '';
      verifyOtpData?.message === 'MANDATORY_MOBILE_NUMBER'
        ? setError('mobile', { type: 'custom', message: verifyOtpData?.description })
        : '';
      verifyOtpData?.message === 'MANDATORY_OTP'
        ? setError(errorField, { type: 'custom', message: verifyOtpData?.description })
        : '';
      verifyOtpData?.message === 'INVALID_OTP'
        ? setError(errorField, { type: 'custom', message: verifyOtpData?.description })
        : '';
      // verifyOtpData?.message === 'SUCCESS'
      //   ? setError(errorField, { type: 'custom', message: verifyOtpData?.description })
      //   : '';
      setVerifyOtpData(verifyOtpData);
      setSelectedChannel(channel);
      const baseUrl = process.env.REACT_APP_API_ENDPOINT;
      let workshopIdString = localStorage.getItem('workshopId');
      let workshopId = Number(workshopIdString);
      apiMethod
        .post(true, `${baseUrl}/v1/analytics/workshop`, {
          event: 'opt verified',
          workshopId: workshopId,
        })
        .catch(() => {});

      // return verifyOtpData;
    } catch (error) {
      console.log('error', error);
    }
  };

  // const initPaymentHandler = async () => {
  //   console.log('in payment handler');
  //   const payload
  // };

  return (
    <>
      <div className="signupFrm">
        <form className="form" onSubmit={handleSubmit(submitHandler)} noValidate>
          <div className="flex justify-between mb-[30px]">
            <div className="signupWrapper mr-[10px]">
              <label htmlFor="firstname" className="signupLabel">
                Firstname
              </label>
              <input
                type="text"
                className={`signupInput ${errors?.firstname?.message ? 'error-red' : 'no-error'}`}
                {...register('firstname', { required: 'Firstname is required' })}
              />
              <p className="error-message">{errors?.firstname?.message}</p>
            </div>
            <div className="signupWrapper">
              <label htmlFor="lastname" className="signupLabel">
                Lastname
              </label>
              <input
                type="text"
                className={`signupInput ${errors?.lastname?.message ? 'error-red' : 'no-error'}`}
                {...register('lastname', { required: 'Lastname is required' })}
              />
              <p className="error-message">{errors?.lastname?.message}</p>
            </div>
          </div>

          <div className="flex justify-between mb-[30px]">
            <div className="signupWrapper w-[100%]">
              <label htmlFor="email" className="signupLabel">
                Email
              </label>
              <input
                type="email"
                className={`signupInput ${errors?.email?.message ? 'error-red' : 'no-error'}`}
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'Invalid email format',
                  },
                })}
              />
              <p className="error-message">{errors?.email?.message}</p>
            </div>
            {/* <div className="">
              <Button
                className={'multi_color_btn'}
                content={'Send OTP'}
                onClick={() => {
                  sendOtpHandler('email');
                }}
              />
            </div> */}
          </div>

          <div className="flex  justify-between mb-[30px]">
            <div className="signupWrapper country_select w-[15%] mr-[5px]">
              <select name="countryCode" {...register('countryCode')}>
                <option value="+91">+91 🇮🇳</option>
                <option value="+971">+971 🇦🇪</option>
                <option value="+966">+966 🇸🇦</option>
                <option value="+1">+1 🇺🇸 🇨🇦</option>
                <option value="+92">+92 🇵🇰</option>
                <option value="+974">+974 🇶🇦</option>
                <option value="+973">+973 🇧🇭 </option>
                <option value="+20">+20 🇪🇬</option>
                <option value="+61">+61 🇦🇺</option>
              </select>
            </div>
            <div className="signupWrapper mobile_form_wrapper w-[50%]">
              <label htmlFor="mobile" className="signupLabel">
                Mobile
              </label>
              <input
                type="text"
                className={`signupInput ${errors?.mobile?.message ? 'error-red' : 'no-error'}`}
                {...register('mobile', {
                  required: 'Mobile is required',
                  minLength: {
                    value: 9,
                    message: 'Minimum length 10',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum length 10',
                  },
                  pattern: {
                    // value: /^\d{1,10}$/,
                    value: /^(0|[1-9][0-9]*)$/,
                    message: 'Invalid mobile format',
                  },
                })}
              />
              <p className="error-message">{errors?.mobile?.message}</p>
            </div>
            <div className="">
              <Button
                className={'multi_color_btn'}
                content={'Send OTP'}
                onClick={() => {
                  sendOtpHandler('mobile');
                }}
              />
            </div>
          </div>

          <div className="flex  justify-between mb-[30px]">
            <div className="signupWrapper w-[66%]">
              <label htmlFor="phonecode" className="signupLabel">
                Phone code
              </label>
              <input
                type="text"
                className={`signupInput ${errors?.mobile?.message ? 'error-red' : 'no-error'}`}
                {...register('phonecode', {
                  required: 'Phone code is required',
                  minLength: {
                    value: 6,
                    message: 'Minimum length 6',
                  },
                  maxLength: {
                    value: 6,
                    message: 'Maximum length 6',
                  },
                })}
              />
              <p className="error-message">{errors?.phonecode?.message}</p>
            </div>
            <div className="">
              <Button
                className={
                  selectedChannel === 'mobile' && verifyOtpData?.message === 'SUCCESS'
                    ? 'green_btn'
                    : 'multi_color_btn'
                }
                content={
                  selectedChannel === 'mobile' && verifyOtpData?.message === 'SUCCESS'
                    ? 'Verified'
                    : 'Verify OTP'
                }
                onClick={() => {
                  verifyOtpHandler('mobile');
                }}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              className={'multi_color_btn btn ml-[15px]'}
              type={'submit'}
              content={'Buy Now'}
              // onClick={initPaymentHandler}
            />
          </div>
        </form>
        <DevTool control={control} />
      </div>
      {isPopup ? (
        <div className="payment-popup">
          <iframe
            id="payment-iframe"
            title="buzz"
            src={paymentLink}
            //onLoad={handleIframeLoad}
            sandbox="allow-forms allow-modals allow-popups-to-escape-sandbox allow-popups allow-scripts allow-top-navigation allow-same-origin"
          />
          <button className="close-button" onClick={() => onCloseClick()}>
            <svg
              stroke="var(--color-background-primary)"
              fill="var(--color-background-primary)"
              strokeWidth="0"
              viewBox="0 0 384 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Signup;
