import React from 'react';
import Button from '../Common/Button';
import InstagramIcon from '@mui/icons-material/Instagram';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import './style.css';

const Footer = () => {
  return (
    <div className="footer_bg width-[98%]">
      <div className="overlay">
        <div className="footer_wrapper main_container py-[15px] px-[10px]">
          <div className="flex item-center justify-start">
            <div className="footer_logo">
              <a href="https://buzzsocial.ai">
                <img
                  src="https://public-buzz-be.s3.eu-west-1.amazonaws.com/webprofilepic/buzzlogowebp.webp"
                  alt="logo"
                />
              </a>
            </div>
            <p className=" align-top terms_link text-slate-700 !font-[10px]">
              WingBerry LLC©️ All Rights Reserved&nbsp;
            </p>
          </div>
          <p className=" text-slate-500">
            <a className="terms_link text-slate-700" href="https://buzzsocial.ai/termsconditions">
              Terms & Conditions&nbsp;
            </a>
            <a className="terms_link text-slate-700" href="https://buzzsocial.ai/privacy">
              | Privacy Policy&nbsp;
            </a>
            <a
              className="terms_link text-slate-700"
              href="https://www.linkedin.com/company/buzzsocial-ai/">
              | LinkedIn&nbsp;
            </a>
            <a className="terms_link text-slate-700" href="">
              | Buzz Support&nbsp;
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
