export const categoriesOption = ['Fashion', 'Fitness'];

export const categoriesOptionDetails = [
  {
    key: 'seats',
    imageURL: 'https://public-buzz-be.s3.eu-west-1.amazonaws.com/webprofilepic/gradientfinal.webp',
    label: '12',
  },
  // {
  //   key: 'language',
  //   imageURL: arabicSvg,
  //   label: 'Arabic',
  // },
  {
    key: 'time',
    imageURL: 'https://public-buzz-be.s3.eu-west-1.amazonaws.com/webprofilepic/calendernew.webp',
    label: '1 Jan - 7:00pm',
  },
];

export const descriptionTiles = [
  {
    key: 'webAudienceDescription',
    heading: 'Who is this workshop for',
    description:
      'Anyone looking to grow their account beyond 5000 followers and make money from social media',
  },
  {
    key: 'webDetails',
    heading: 'Preparing for the experience',
    description:
      'Anyone looking to grow their account beyond 5000 followers and make money from social media',
  },
];

export const FormArray = ['Signup', 'Verification', 'Details'];

export const languageMapper = { EN: 'English', AR: 'Arabic' };
