import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import ImageInfoCard from '../ImageInfoCard';
import Button from '../Button';
import Util from '../../../Util/Util';

const WorkshopDetailCard = ({ data }) => {
  console.log('data card', data);
  const navigate = useNavigate();
  let id = data.workshopId;
  console.log(' workshop id is==>', id);
  return (
    <div className="workshop_card my-[60px]">
      <div className="flex mx-[20px] mb-10 my-2 items-center">
        <div className="card_content flex w-[100%]">
          <p className="text-[20px] flex pt-[0%] font-bold w-3/4 justify-center align-text-bottom text-buzzpurple">
            {data?.title}
          </p>
          <div className=" flex w-[75%] h-[100%] justify-center">
            <img
              src={data.imageURL}
              alt="person"
              className="w-[100%] max-w-32 max-h-32 border-gray-200 border-spacing-14 border-1 shadow-xl shadow-gray-500 rounded-md justify-center"
            />
          </div>
        </div>
      </div>
      <div className="mx-[14px] image_card_wrapper">
        <ImageInfoCard userName={data?.userName} profilePic={data?.creatorProfilePic} />
      </div>
      <div className="flex mx-[14px] mt-5 leading-snug">
        <p
          className="pl-[5px] pr-[45px] font-medium text-[14px]"
          dangerouslySetInnerHTML={{ __html: data?.description }}></p>
      </div>
      <div className="workshop_details flex-col">
        <div className="py-[0px] w-[100%] flex flex-col">
          <p className="text-[14px] pt-4 font-bold text-neutral-700 mr-auto">Date & Time</p>
          <p className="text-[14px] font-semibold text-buzzpink mr-auto">{`${Util?.formatDate(data?.date)} - ${Util?.formatTime(data?.time)}`}</p>
        </div>
      </div>
      <div className="payment_info pl-[20px] pr-[10px] py-[0px] flex justify-between">
        <div className="payment_content">
          <p className="text-[14px] font-bold text-neutral-700">Payment required</p>
          <p className="text-[#e25aca] text-[14px] font-semibold">
            {`${data?.currency} ${data?.price?.toFixed(2)}`}{' '}
          </p>
        </div>
        <Button
          content={'Buy Tickets'}
          className={'multi_color_btn w-auto'}
          onClick={() => {
            navigate(`/workshop/view/${id}`);
          }}
        />
      </div>
    </div>
  );
};

export default WorkshopDetailCard;
